import NavBar from './NavigationComponents/Navbar';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Contact from './ContactComponent';
import Home from './HomeComponent';
import Footer from './FooterComponent';
import Projects from './ProjectsComponent';
import Experience from './ExperienceComponent';




const Main=()=>{
        return(
            <BrowserRouter>
                <NavBar />
                <Switch>
                    <Route component={Home} path='/'  exact />
                    <Route component={Experience} path='/experience'  />
                    <Route component={Projects} path='/projects'/>
                    <Route component={Contact} path='/contact'/>
                </Switch> 
                <Footer /> 
            </BrowserRouter>
            

        );



    }



export default Main;



