import Main from './components/MainComponent';
import './App.css'



function App() {
  return (
    <div className="my-component">
    <Main />
    </div>
  );
}

export default App;
