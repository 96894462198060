import React from 'react';
import { Container, Row, Col} from 'reactstrap';
import './styles/style.css'


function About(){

        return(
            <section className="about-section">
                <Container className="themed-container about-box" fluid={true}>
                    <Row> 
                        <Col xl={{size:10, offset:1 }}>
                            <Row>
                                <Col>
                                    <div className="about-header">
                                        <h2  >About Me</h2>
                                    </div>
                                    <div className="about-me">
                                        <p>Software Engineer Intern interested in FullStack or Backend Engineering seeking New Graduate Roles beginning June 2022 or August 2022.</p>
                                        <p>
                                            Unlike every other computer science aspirant, I didn't start programming nor had 
                                            a passion for computer engineering since 4th grade. I spent the early years of my high
                                            school solving complex mathematical equations, trigonometry problems, and sequence and
                                            series. The nights, I devoted to understanding the logic of the QBASIC programs in school,
                                            are still vivid in my memory. I began to see a correlation between Mathematics and Computer 
                                            Science, which led me to pursue a degree in Computer Science.                                            
                                        </p>
                                        <p>
                                            I am currently a senior studying at Caldwell University, majoring in Computer Science and Mathematics
                                            with a concentration in Software Engineering. I learned Object-oriented Java Programming, Data Structures 
                                            and Algorithms, Operating Systems, and Software Engineering during my years at Caldwell University. The more 
                                            I learned about Computer Science, the more I realized how much I have yet to learn. I taught myself Python, 
                                            JavaScript, and its frameworks such as React, React Native, Nodejs, Django through Udemy, Coursera, and Youtube
                                            tutorials (Shout-out to freeCodeCamp.org). I spend my free time reading tech articles, learning new tools and 
                                            technologies, and working on side projects.                                        
                                        </p>
                                        <p>
                                            I am also currently working as a Software Engineer Intern at WarnerMedia. I worked at Langan as a Software Engineer Intern for 8 months working 
                                            on the development of end-to-end projects. I have utilized my time writing code for a full backend projects using 
                                            .NET along with a few contributions on the React projects. Prior to that, I worked as a Software Engineer 
                                            Intern at Caldwell University leading the project to develop database management system software. I am well-versed in 
                                            software engineering practices and incorporate them into my projects. I am particularly fond of resolving complex and 
                                            difficult challenges.
                                        </p>
                                        <p>
                                            I am interested in working with a technology-driven or product-based firm/industry especially FinTech. The idea of working in a fast-paced environment
                                            using the latest technologies, developing high-performing algorithms to deal with everyday problems, growing myself while learning, and putting
                                            myself on top of cutting-edge technology, inspire me towards this industry. I have a substantial amount of internship experience in FullStack
                                            Software development and a good understanding of Data Structures and Algorithms, Machine Learning, and Reinforcement Learning. Please feel free
                                            to email me or message me on LinkedIn with interesting opportunities. 
                                        </p>
                                        <p>
                                            I appreciate you taking the time to learn about me, and I look forward to hearing from you.
                                        </p>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </section>   
        
        )
    }


export default About;
