import React from 'react';
import rightPic from './assets/images/rixant4.jpeg';
import {Container, Row, Col } from 'reactstrap';
import { FaFacebookF, FaLinkedinIn, FaInstagram, FaGithub, FaTwitter } from "react-icons/fa";
import './styles/style.css'
import About from './AboutComponent';
import FadeIn from 'react-fade-in';
import Education from './EducationComponent';



const Profile=()=>{
    return(
    <section className="hero-section spad mb-5"  style={{backgroundColor:"#f0f0f0"}}>
       <Container className="themed-container content-box " fluid={false}>
            <Row>
                <Col xl={{size:10, offset:1 }}>
                    <Row>
                        <Col sm="12" lg={{ size: 6}} className="info-box">
                            <div className="hero-text" >
                                <h2>Rishant Rokaha</h2>
                                <hr />
                                <p><h3>Software Engineer</h3></p>
                              
                            </div>
                            <div className="hero-info .d-lg-inline-block d-block " >
                                <h2 > General Info</h2>
                                <hr  />
                                <ul>
                                    <li><span className="d-block d-md-inline-block">Date of Birth </span>Feb **, ****</li>
                                    <li><span className="d-block d-md-inline-block">Address </span>Bloomfield, New Jersey, US</li>
                                    <li><span className="d-block  d-md-inline-block">E-mail </span>**********@gmail.com</li>
                                    <li><span className="d-block d-md-inline-block">Phone </span>+1 973 XXX XXXX</li>
                                </ul> 
                            </div>
                        </Col>
                        <Col sm="12" lg={{ size: 6}}>
                            <figure className="hero-image">
                                <img src={rightPic} className="my-image"  alt="myimage" />
                            </figure>
                            <Social />
                        </Col>
                    </Row>
                </Col>
            </Row>  
        </Container>
        </section>
    )

    
}


const Social=()=>{
    return(
    <section className="social-section">
        <h2 className="d-flex justify-content-center">Social Profiles</h2>
        <div className="social-link-warp "> 

            <div className="social-links ">
                <a href="https://www.facebook.com/"><i className="fa fa-facebook"><FaFacebookF /></i></a>
                <a href="https://www.instagram.com/"><i className="fa fa-instagram"><FaInstagram /></i></a>
                <a href="https://twitter.com/"><i  className="fa fa-twitter"><FaTwitter /></i></a>  
                <a href="https://www.linkedin.com/in/"><i className="fa fa-linkedin"><FaLinkedinIn /></i></a>
                <a href="https://github.com/"><i  className="fa fa-github"><FaGithub /></i></a>   
            </div>
            
        </div>
    </section>
    
    );

}


function Home(){

 
    return(
        <React.Fragment >
            <FadeIn delay="300" >
            <Profile />  
            </FadeIn>
            <FadeIn delay="300" >
            <About /> 
            <Education />
            </FadeIn>
        </React.Fragment>

    
    )
}








export default Home;
