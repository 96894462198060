import React from 'react';
import { Container, Row, Col} from 'reactstrap';
import './styles/style.css';
import sxc from './assets/images/sxc.jpeg';
import cu from './assets/images/cu.jpeg';


function Education(){

        return(
            <section className="edu-section spad mb-5"  >
                <Container className="themed-container edu-box " fluid={false}>
                    <Row>
                        <Col xl={{size:10, offset:1 }}> 
                            <div className="edu-header">
                                <h2>Education</h2>
                            </div>  
                            <Row>  
                            <Col sm="12" lg={{ size: 6}} className="edu-box">
                                    <div class="profile-card text-center"><img src={cu} class="img img-responsive" alt="cu"/>
                                        <div class="profile-content">
                                            <div class="profile-name">Caldwell University (2018 - 2022)
                                                <p>Caldwell, New Jersey</p>
                                            </div>
                                            <div class="profile-description">
                                                Bachelor in Computer Science and Mathematics 
                                            </div>
                                        </div>
                                    </div>
                                </Col>              
                                <Col sm="12" lg={{ size: 6}} className="edu-box">
                                    <div class="profile-card text-center"><img src={sxc} class="img img-responsive" alt="sxc"/>
                                        <div class="profile-content">
                                            <div class="profile-name">St. Xavier's College (2015 - 2017)
                                                <p>Maitighar, Kathmandu</p>
                                            </div>
                                            <div class="profile-description">
                                                Cambridge GCE A-Level 
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <div className="edu-quote">
                                <p style={{"color":"grey"}}>❝Education is the most powerful weapon which you can use to change the world❞ - Nelson Mandela</p>
                            </div>
                        </Col>
                    </Row>  
                </Container>
            </section>
        )
    }


export default Education;