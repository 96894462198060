import React from "react";
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import {CardText,
        CardBody,
        CardTitle,
        CardSubtitle
    } from 'reactstrap';
import { Container, Row, Col } from 'reactstrap';
import './styles/style.css';


const Experience = () => {


    return(
            <section className="project-section ">
                <Container className="themed-container project-content" fluid={true}>
                    <Row> 
                        <Col xl={{size:12 }}>
                            <Row>
                                <Col>
                                    <div className="projects-header" >
                                        <h2>Experience</h2>
                                    </div>
                                    <VerticalTimeline className="vertical-timeline-custom-line">

                                        <VerticalTimelineElement
                                            className="vertical-timeline-element--work mb-5 mt-5"
                                            contentStyle={{ background:'#E8E8E8',color: 'black' }}
                                            contentArrowStyle={{ borderRight: '7px solid  black' }}
                                            date="(5th July, 2022 - Present)"
                                            iconStyle={{ background: '#E8E8E8', color: 'black' }}
                                            icon={<langanLogo/>}
                                        >
                                            <CardBody>
                                                <CardTitle tag="h4">Software Engineer</CardTitle>
                                                <CardSubtitle tag="h6" className="mb-2 text-muted">Credit Suisse</CardSubtitle>
                                                <CardText>• Working in a Data Cloud Tech Team to build data pipeline using cutting edge technologies like SnowFlake, Kafka, HDFS.</CardText>
                                            </CardBody>
    
                                        </VerticalTimelineElement>

                                        <VerticalTimelineElement
                                            className="vertical-timeline-element--work mb-5 mt-5"
                                            contentStyle={{ background:'#E8E8E8',color: 'black' }}
                                            contentArrowStyle={{ borderRight: '7px solid  black' }}
                                            date="(19th February, 2022 - Present)"
                                            iconStyle={{ background: '#E8E8E8', color: 'black' }}
                                            icon={<langanLogo/>}
                                        >
                                            <CardBody>
                                                <CardTitle tag="h4">Open Source Developer</CardTitle>
                                                <CardSubtitle tag="h6" className="mb-2 text-muted">The Zulip Open Source Project</CardSubtitle>
                                                <CardText>• Enhanced UI and fixed bugs in collaboration with other open source contributors.</CardText>
                                                <CardText>• Practiced best software engineering practices to deliver high quality software.</CardText>
                                            </CardBody>
    
                                        </VerticalTimelineElement>

                                        <VerticalTimelineElement
                                            className="vertical-timeline-element--work mb-5 mt-5"
                                            contentStyle={{ background:'#E8E8E8',color: 'black' }}
                                            contentArrowStyle={{ borderRight: '7px solid  black' }}
                                            date="(24th January, 2022 - 15th April, 2022)"
                                            iconStyle={{ background: '#E8E8E8', color: 'black' }}
                                            icon={<langanLogo/>}
                                        >
                                            <CardBody>
                                                <CardTitle tag="h4">Software Engineer Intern</CardTitle>
                                                <CardSubtitle tag="h6" className="mb-2 text-muted">Warner Bros. Discovery (WarnerMedia)</CardSubtitle>
                                                <CardText>• Worked on a team to develop Emmy Award Winning traffic systems, from analysis to support using an agile methodology.</CardText>
                                                <CardText>• Developed log grids and grid templates for traffic systems for HBO, CNN, Cartoon Network, TBS, and TNT TV advertisements, programs, and events using React/Redux, TypeScript.</CardText>
                                                <CardText>• Designed the application features for each sprint in collaboration with fellow developers.</CardText>
                                                <CardText>• Built automated integration tests on Cypress to detect UI issues caused due to code changes.</CardText>
                                                <CardText>• Resolved console errors and warnings working closely with testers.</CardText>
                                            </CardBody>
    
                                        </VerticalTimelineElement>


                                        <VerticalTimelineElement
                                            className="vertical-timeline-element--work mb-5 mt-5"
                                            contentStyle={{ background:'#E8E8E8',color: 'black' }}
                                            contentArrowStyle={{ borderRight: '7px solid  black' }}
                                            date="(7th June, 2021 - 17th January, 2022)"
                                            iconStyle={{ background: '#E8E8E8', color: 'black' }}
                                            icon={<langanLogo/>}
                                        >
                                            <CardBody>
                                                <CardTitle tag="h4">Software Engineer Intern</CardTitle>
                                                <CardSubtitle tag="h6" className="mb-2 text-muted">Langan</CardSubtitle>
                                                <CardText>• Developed database-centric solutions in an Agile/Scrum environment using Git, Azure DevOps, MS SQL, .NET, and React/Redux.</CardText>
                                                <CardText>• Built secured RESTful Web API services using Entity Framework to create appeals, upload and download files in the MS SQL database.</CardText>
                                                <CardText>• Wrote complex SQL queries and stored procedures for ETL Database model to create database objects dynamically from user inputs in SQL Server Management Studio.</CardText>
                                                <CardText>• Scheduled background jobs using Quartz.net and minimized code redundancy.</CardText>
                                                <CardText>• Developed test cases, performed unit tests for web APIs using xUnit and end-to-end tests.</CardText>
                                                <CardText>• Wrote clean, readable, and detailed documentation for clients and internal developer use.</CardText>
                                            </CardBody>
        
                                        </VerticalTimelineElement>


                                        <VerticalTimelineElement
                                            className="vertical-timeline-element--work mb-5 mt-5"
                                            contentStyle={{ background:'#E8E8E8',color: 'black' }}
                                            contentArrowStyle={{ borderRight: '7px solid  black' }}
                                            date="(7th February, 2021 - 7th May, 2021)"
                                            iconStyle={{ background: '#E8E8E8', color: 'black' }}
                                        >
                                            <CardBody>
                                                <CardTitle tag="h4">Software Engineer Intern</CardTitle>
                                                <CardSubtitle tag="h6" className="mb-2 text-muted">School of Business and Computer Science Department, Caldwell University</CardSubtitle>
                                                <CardText>• Developed responsive web based database management system software to manage internal data and process.</CardText>
                                                <CardText>• Developed backend solutions and software components, and devised the detailed documentation.</CardText>
                                                <CardText>• Prepared complex test cases, performed JavaScript unit tests using Jest, integration tests, and end-to-end tests.</CardText>
                                                <CardText>• Developed new databases and customized the existing ones writing SQL queries, and performed database normalization.</CardText>
                                            </CardBody>
        
                                        </VerticalTimelineElement>


                                        <VerticalTimelineElement
                                            className="vertical-timeline-element--work mb-5 mt-5"
                                            contentStyle={{ background:'#E8E8E8',color: 'black' }}
                                            contentArrowStyle={{ borderRight: '7px solid  black' }}
                                            date="(7th April, 2021 - present)"
                                            iconStyle={{ background: '#E8E8E8', color: 'black' }}
                                        >
                                            <CardBody>
                                                <CardTitle tag="h4">Microsoft Learn Student Ambassador</CardTitle>
                                                <CardSubtitle tag="h6" className="mb-2 text-muted">Microsoft</CardSubtitle>
                                                <CardText>• Worked on the Teams app with fellow MSPs and brainstorm many ideas related to software engineering practices and deep learning applications</CardText>
                                                <CardText>• Acquired knowledge on Microsoft services that include but not limited to Microsoft Azure, Office 365.</CardText>
                                                <CardText>• Encouraged fellow peers to learn and develop coding skills with Microsoft Learn.</CardText>
                                            </CardBody>
        
                                        </VerticalTimelineElement>


                                        <VerticalTimelineElement
                                            className="vertical-timeline-element--work mb-5 mt-5"
                                            contentStyle={{ background:'#E8E8E8',color: 'black' }}
                                            contentArrowStyle={{ borderRight: '7px solid  black' }}
                                            date="(21st January, 2020 - 15th March, 2020)"
                                            iconStyle={{ background: '#E8E8E8', color: 'black' }}
                                        >
                                            <CardBody>
                                                <CardTitle tag="h4">Student IT Technician</CardTitle>
                                                <CardSubtitle tag="h6" className="mb-2 text-muted"> IT Services, Caldwell University</CardSubtitle>
                                                <CardText>• Used Kaseya for ticket management, patch management, and remote access to different machines within the domain.</CardText>
                                                <CardText>• Provided on-site, remote, and phone assistance to resolve the hardware and software issues of all faculty, staff, and students.</CardText>
                                                <CardText>• Worked on a team with other IT professionals to delegate tickets and discuss the potential ideas to fix different technical issues.</CardText>
                                            </CardBody>
        
                                        </VerticalTimelineElement>


                                        <VerticalTimelineElement
                                            className="vertical-timeline-element--work mb-5 mt-5"
                                            contentStyle={{ background:'#E8E8E8',color: 'black' }}
                                            contentArrowStyle={{ borderRight: '7px solid  black' }}
                                            date="(9th September, 2019 - 15th March, 2020)"
                                            iconStyle={{ background: '#E8E8E8', color: 'black' }}
                                        >
                                            <CardBody>
                                                <CardTitle tag="h4">Media Services Technician</CardTitle>
                                                <CardSubtitle tag="h6" className="mb-2 text-muted"> IT Services, Caldwell University</CardSubtitle>
                                                <CardText>• Assisted with the planning of event logistics</CardText>
                                                <CardText>• Setup of all audio/visual for different events and presentations.</CardText>
                                            </CardBody>
        
                                        </VerticalTimelineElement>


                                        <VerticalTimelineElement
                                            className="vertical-timeline-element--work mb-5 mt-5"
                                            contentStyle={{ background:'#E8E8E8',color: 'black' }}
                                            contentArrowStyle={{ borderRight: '7px solid  black' }}
                                            date="(15th October, 2019 - 7th February, 2020)"
                                            iconStyle={{ background: '#E8E8E8', color: 'black' }}
                                        >
                                            <CardBody>
                                                <CardTitle tag="h4">CS and Mathematics Tutor</CardTitle>
                                                <CardSubtitle tag="h6" className="mb-2 text-muted">Academic Success Center, Caldwell University</CardSubtitle>
                                                <CardText>• Tutored peers on one-to-one in Java Programming, Data Structures and Algorithms, Algebra, and Advanced Calculus.</CardText>
                                                <CardText>• Collaborated with professors to discuss the academic needs of the students.</CardText>
                                                <CardText>• Customized tutoring and instructions to meet the needs of diverse students and boost their test scores.</CardText>
                                            </CardBody>
                                        </VerticalTimelineElement>


                                        <VerticalTimelineElement
                                            className="vertical-timeline-element--work mb-5 mt-5"
                                            contentStyle={{ background:'#E8E8E8',color: 'black' }}
                                            contentArrowStyle={{ borderRight: '7px solid  black' }}
                                            date="(7th November, 2019)"
                                            iconStyle={{ background: '#E8E8E8', color: 'black' }}
                                        >
                                            <CardBody>
                                            <iframe width="100%" height="250" src="https://www.youtube.com/embed/3yqx_4UxVXg" title="YouTube video player" 
                                            frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                                <CardTitle tag="h4">Event Manager at Caldwell Students Association</CardTitle>
                                                <CardSubtitle tag="h6" className="mb-2 text-muted">Caldwell University</CardSubtitle>
                                                <CardText>• Involved in Organizing and Planning the Cultural Events in College.</CardText>
                                                <CardText><a href="https://www.youtube.com/watch?v=3yqx_4UxVXg">· View the full event on Youtube</a></CardText>
                                            </CardBody>
                                        </VerticalTimelineElement>

    
                                    </VerticalTimeline>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </section>   
        )
    }



export default Experience;