import React from "react";
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import {CardText,
        CardBody,
        CardTitle,
        CardImg,
        CardSubtitle,
        Button
        } from 'reactstrap';
import { Container, Row, Col } from 'reactstrap';
import appdemo from './assets/images/demo.gif';
import mobilenetdemo from './assets/images/mobilenetdemo.gif';
import tododemo from './assets/images/tododemo.gif';
import Dash from './assets/images/dash.gif';
import insertionsortdemo from './assets/images/insertsortdemo.gif';
import sentimentdemo from './assets/images/sentiment.gif';
import './styles/style.css';


const Projects = () => {


    return(
            <section className="project-section ">
                <Container>
                    <Row>
                        <div class="cover" >
                            {/* <img src={cov} /> */}
                        </div>
                    </Row>
                </Container>
                <Container className="themed-container project-content" fluid={true}>
 
                    <Row> 
                        <Col xl={{size:10, offset:1 }}>
                            <Row>
                                <Col>
                                    <div className="projects-header" >
                                        <h2> Projects </h2>
                                    </div>
                                    
                                    <VerticalTimeline className="vertical-timeline-custom-line">

                                        <VerticalTimelineElement
                                            className="vertical-timeline-element--work mb-5 mt-5"
                                            contentStyle={{ background:'#E8E8E8',color: 'black' }}
                                            contentArrowStyle={{ borderRight: '7px solid  black' }}
                                            // date="2011 - present"
                                            iconStyle={{ background: '#E8E8E8', color: 'black' }}
                                        >
                                            <CardImg top width="50%" height="50%" src="https://user-images.githubusercontent.com/10624937/42135619-d90f2f28-7d12-11e8-8823-82b970a54d7e.gif" alt="Card image cap" />
                                            <CardBody>
                                                <CardTitle tag="h4">DeepQ Navigation</CardTitle>
                                                <CardSubtitle tag="h6" className="mb-2 text-muted">Technologies Used : [Python, DQN, Reinforcement Learning]</CardSubtitle>
                                                <CardText>Trained an agent using Deep Q-Network (DQN) algorithm to navigate a virtual world and collect yellow bananas while avoiding blue bananas.
                                                </CardText>
                                                <Button  className="repo-button" style={{backgroundColor:"whitesmoke", fontWeight:"500"}}><a href='https://github.com/Rixant/Deep-Reinforcement-Learning-Nanodegree/tree/main/Project%201%20-%20Navigation' >Source Code</a> </Button>
                                            </CardBody>
        
                                        </VerticalTimelineElement>


                                        <VerticalTimelineElement
                                            className="vertical-timeline-element--work mb-5 mt-5"
                                            contentStyle={{ background:'#E8E8E8',color: 'black' }}
                                            contentArrowStyle={{ borderRight: '7px solid  black' }}
                                            // date="2011 - present"
                                            iconStyle={{ background: '#E8E8E8', color: 'black' }}
                                        >
                                            <CardImg top width="50%" height="50%" src="https://user-images.githubusercontent.com/10624937/43851024-320ba930-9aff-11e8-8493-ee547c6af349.gif" alt="Card image cap" />
                                            <CardBody>
                                                <CardTitle tag="h4">Continuous Control</CardTitle>
                                                <CardSubtitle tag="h6" className="mb-2 text-muted"> Technologies Used : [Python, DDPG, Reinforcement Learning]</CardSubtitle>
                                                <CardText>Train an agent using Deep Deterministic Policy Gradient (DDPG) to learn to move a double-jointed arm to target locations.
                                                </CardText>
                                                <Button  className="repo-button" style={{backgroundColor:"whitesmoke", fontWeight:"500"}}><a href='https://github.com/Rixant/Deep-Reinforcement-Learning-Nanodegree/tree/main/Project%202%20-%20Continous%20Control' >Source Code</a> </Button>
                                            </CardBody>
        
                                        </VerticalTimelineElement>


                                        <VerticalTimelineElement
                                            className="vertical-timeline-element--work mb-5 mt-5"
                                            contentStyle={{ background:'#E8E8E8',color: 'black' }}
                                            contentArrowStyle={{ borderRight: '7px solid  black' }}
                                            // date="2011 - present"
                                            iconStyle={{ background: '#E8E8E8', color: 'black' }}
                                        >
                                            <CardImg top width="50%" height="50%" src="https://user-images.githubusercontent.com/10624937/42135623-e770e354-7d12-11e8-998d-29fc74429ca2.gif" alt="Card image cap" />
                                            <CardBody>
                                                <CardTitle tag="h4">Multi-Agent Tennis Player</CardTitle>
                                                <CardSubtitle tag="h6" className="mb-2 text-muted">Technologies Used : [Python, DDPG, Reinforcement Learning]</CardSubtitle>
                                                <CardText>Trained two agents using Deep Deterministic Policy Gradient (DDPG) to control rackets to play tennis.
                                                </CardText>
                                                <Button  className="repo-button" style={{backgroundColor:"whitesmoke", fontWeight:"500"}}><a href='https://github.com/Rixant/Deep-Reinforcement-Learning-Nanodegree/tree/main/Project%203%20-%20MultiAgent%20Tennis' >Source Code</a> </Button>
                                            </CardBody>
        
                                        </VerticalTimelineElement>
                                        

                                        <VerticalTimelineElement
                                            className="vertical-timeline-element--work mb-5 mt-5"
                                            contentStyle={{ background:'#E8E8E8',color: 'black' }}
                                            contentArrowStyle={{ borderRight: '7px solid  black' }}
                                            // date="2011 - present"
                                            iconStyle={{ background: '#E8E8E8', color: 'black' }}
                                        >
                                            <CardImg top width="50%" height="50%" src={sentimentdemo} alt="Card image cap" />
                                            <CardBody>
                                                <CardTitle tag="h4">Sentiment Analysis App</CardTitle>
                                                <CardSubtitle tag="h6" className="mb-2 text-muted">Technologies Used : [HTML, Python, Machine Learning, AWS (SageMaker, S3, Lambda, API Gateway)]</CardSubtitle>
                                                <CardText>Developed a web app that classifies the sentiment of a person based on the movie reviews by deploying the machine learning model using AWS lambda, API Gateway.
                                                </CardText>
                                                <Button  className="repo-button" style={{backgroundColor:"whitesmoke", fontWeight:"500"}}><a href='https://github.com/Rixant/ML-Engineer-Nanodegree/tree/main/Sagemaker_Sentiment_Analysis' >Source Code</a> </Button>
                                            </CardBody>
        
                                        </VerticalTimelineElement>


                                        <VerticalTimelineElement
                                            className="vertical-timeline-element--work mb-5 mt-5"
                                            contentStyle={{ background:'#E8E8E8',color: 'black' }}
                                            contentArrowStyle={{ borderRight: '7px solid  black' }}
                                            // date="2011 - present"
                                            iconStyle={{ background: '#E8E8E8', color: 'black' }}
                                        >
                                            <CardImg top width="50%" height="50%" src={insertionsortdemo} alt="Card image cap" />
                                            <CardBody>
                                                <CardTitle tag="h4">Sorting Algorithm Visualization</CardTitle>
                                                <CardSubtitle tag="h6" className="mb-2 text-muted">Technologies Used : [React, JavaScript]</CardSubtitle>
                                                <CardText> Built a web application that visualizes the 5 different sorting algorithms: BubbleSort, InsertionSort, SelectionSort, MergeSort, QuickSort</CardText>
                                                <Button  className="repo-button" style={{backgroundColor:"whitesmoke", fontWeight:"500"}}><a href='https://github.com/Rixant/Sorting-Algorithms-Visualization'>Source Code</a> </Button>
                                            </CardBody>
        
                                        </VerticalTimelineElement>


                                        <VerticalTimelineElement
                                            className="vertical-timeline-element--work mb-5 mt-5"
                                            contentStyle={{ background:'#E8E8E8',color: 'black' }}
                                            contentArrowStyle={{ borderRight: '7px solid  black' }}
                                            // date="2011 - present"
                                            iconStyle={{ background: '#E8E8E8', color: 'black' }}
                                        >
                                            <CardImg top width="50%" height="50%" src={Dash} alt="Card image cap" />
                                            <CardBody>
                                                <CardTitle tag="h4">Business Intelligence Dashboard</CardTitle>
                                                <CardSubtitle tag="h6" className="mb-2 text-muted">Technologies Used : [ASP.NET, Angular, C#, JavaScript, TypeScript]</CardSubtitle>
                                                <CardText> Built a full-stack dashboard that visualizes the statistical data of sales, keeps the track of orders and detects the status of servers health.
                                                </CardText>
                                                <Button  className="repo-button" style={{backgroundColor:"whitesmoke", fontWeight:"500"}}><a href='https://github.com/Rixant/Dashboard' >Source Code</a> </Button>
                                            </CardBody>
        
                                        </VerticalTimelineElement>


                                        <VerticalTimelineElement
                                            className="vertical-timeline-element--work mb-5 mt-5"
                                            contentStyle={{ background:'#E8E8E8',color: 'black' }}
                                            contentArrowStyle={{ borderRight: '7px solid  black' }}
                                            // date="2011 - present"
                                            iconStyle={{ background: '#E8E8E8', color: 'black' }}
                                        >
                                            <CardImg top width="50%" height="50%" src="https://miro.medium.com/max/3840/1*oB3S5yHHhvougJkPXuc8og.gif" alt="Card image cap" />
                                            <CardBody>
                                                <CardTitle tag="h4">Cat Dog Classifier</CardTitle>
                                                <CardSubtitle tag="h6" className="mb-2 text-muted">Technologies Used : [Python, Jupyter Notebook, CNN]</CardSubtitle>
                                                <CardText>It is a neural network model to classify the dogs and cats from its images. The model is trained using more than
                                                     25000 images of cats and dogs. It is built using the keras framework with Tensorflow backend . . .
                                                </CardText>
                                                <Button  className="repo-button" style={{backgroundColor:"whitesmoke", fontWeight:"500"}}><a href='https://github.com/Rixant/Dog-Cat-Classifier-using-CNN' >Source Code</a> </Button>
                                            </CardBody>
        
                                        </VerticalTimelineElement>


                                        <VerticalTimelineElement
                                            className="vertical-timeline-element--work mb-5 mt-5 "
                                            contentStyle={{ background:'#E8E8E8',color: 'black' }}
                                            contentArrowStyle={{ borderRight: '7px solid  black' }}
                                            // date="2011 - present"
                                            iconStyle={{ background: '#E8E8E8', color: 'black' }}
                                        >
                                            <CardImg top width="50%" height="50%" src="https://user-images.githubusercontent.com/23068820/43353830-8e2c9548-925e-11e8-894a-5d9409516df1.png" alt="Card image cap" />
                                            <CardBody>
                                                <CardTitle tag="h4">Handwritten Digit Recognition</CardTitle>
                                                <CardSubtitle tag="h6" className="mb-2 text-muted">Technologies Used : [Python, Jupyter Notebook, Simple Neural Network]</CardSubtitle>
                                                <CardText>It is a simple neural network model built from the scratch to predict the handwritten digit from its image pixels. The Neural Network model is prepared using 784 input nodes and 3 layers with 128 nodes in first . . . .'</CardText>
                                                <Button  className="repo-button" style={{backgroundColor:"whitesmoke", fontWeight:"500"}}><a href='https://github.com/Rixant/Handwritten-Digit-Recognition-NeuralNetwork' >Source Code</a> </Button>
                                            </CardBody>
        
                                        </VerticalTimelineElement>


                                        <VerticalTimelineElement
                                            className="vertical-timeline-element--work mb-5 mt-5"
                                            contentStyle={{ background:'#E8E8E8',color: 'black' }}
                                            contentArrowStyle={{ borderRight: '7px solid  black' }}
                                            // date="2011 - present"
                                            iconStyle={{ background: '#E8E8E8', color: 'black' }}
                                        >
                                            <CardImg  width="20%" height="10%" src={appdemo} alt="Card image cap" />
                                            <CardBody>
                                                <CardTitle tag="h4">Tourism App</CardTitle>
                                                <CardSubtitle tag="h6" className="mb-2 text-muted">Technologies Used : [JavaScript, ReactNative, NodeJS, ExpressJS, MongoDB]</CardSubtitle>
                                                <CardText> Built a Fullstack mobile application for ios and android. It lets you to book hotels and other tourism sports and activities
                                                    through online.
                                                </CardText>
                                                <Button  className="repo-button" style={{backgroundColor:"whitesmoke", fontWeight:"500"}}><a href='https://github.com/Rixant/ProjectZero-TourismApp' >Source Code</a> </Button>
                                            </CardBody>
        
                                        </VerticalTimelineElement>


                                        <VerticalTimelineElement
                                            className="vertical-timeline-element--work mb-5 mt-5"
                                            contentStyle={{ background:'#E8E8E8',color: 'black' }}
                                            contentArrowStyle={{ borderRight: '7px solid  black' }}
                                            // date="2011 - present"
                                            iconStyle={{ background: '#E8E8E8', color: 'black' }}
                                        >
                                            <CardImg top width="50%" height="50%" src={mobilenetdemo} alt="Card image cap" />
                                            <CardBody>
                                                <CardTitle tag="h4">MobileNet Application</CardTitle>
                                                <CardSubtitle tag="h6" className="mb-2 text-muted">Technologies Used : [JavaScript, ReactNative, TensorflowJs]</CardSubtitle>
                                                <CardText> Deployed the mobilenet ML model using TensorflowJs to predict/classify the image.
                                                </CardText>
                                                <Button  className="repo-button" style={{backgroundColor:"whitesmoke", fontWeight:"500"}}><a href='https://github.com/Rixant/Multiplatform-App-Powered-By-AI' >Source Code</a> </Button>
                                            </CardBody>
        
                                        </VerticalTimelineElement>


                                        <VerticalTimelineElement
                                            className="vertical-timeline-element--work mb-5 mt-5"
                                            contentStyle={{ background:'#E8E8E8',color: 'black' }}
                                            contentArrowStyle={{ borderRight: '7px solid  black' }}
                                            // date="2011 - present"
                                            iconStyle={{ background: '#E8E8E8', color: 'black' }}
                                        >
                                            <CardImg top width="50%" height="50%" src={tododemo} alt="Card image cap" />
                                            <CardBody>
                                                <CardTitle tag="h4">ToDoApp</CardTitle>
                                                <CardSubtitle tag="h6" className="mb-2 text-muted">Technologies Used : [Angular, JavaScript, TypeScript]</CardSubtitle>
                                                <CardText> Built a simple web app that allows you to organize the daily tasks.
                                                </CardText>
                                                <Button  className="repo-button" style={{backgroundColor:"whitesmoke", fontWeight:"500"}}><a href='git@github.com:Rixant/ToDoApp.git' >Source Code</a> </Button>
                                            </CardBody>
        
                                        </VerticalTimelineElement>



                                        <VerticalTimelineElement
                                            className="vertical-timeline-element--work mb-5 mt-5"
                                            contentStyle={{ background:'#E8E8E8',color: 'black' }}
                                            contentArrowStyle={{ borderRight: '7px solid  black' }}
                                            // date="2011 - present"
                                            iconStyle={{ background: '#E8E8E8', color: 'black' }}
                                        >
                                            <CardImg top width="50%" height="50%" src="https://www.stallionglobal.com/uploads/links/Title_68.jpg" alt="Card image cap" />
                                            <CardBody>
                                                <CardTitle tag="h4">Library Management Software</CardTitle>
                                                <CardSubtitle tag="h6" className="mb-2 text-muted">Technologies Used : [Java, JavaFX, MySQL, Intellij, JDBC]</CardSubtitle>
                                                <CardText>A library management system implement using concepts of OOP in JAVA, GUI desgined using Java Swing and MS SQL as database. Librarian can search book by title can search book by subject can search book by . . .</CardText>
                                                <Button  className="repo-button" style={{backgroundColor:"whitesmoke", fontWeight:"500"}}><a href='https://github.com/ManisMalakar/LibraryManagementSystem' >Source Code</a> </Button>
                                            </CardBody>
        
                                        </VerticalTimelineElement>
    
                                    </VerticalTimeline>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </section>   
        )        
    }



export default Projects;